<template>
  <div>
    <el-row v-if="type != 'trainingTaskList'">
      <el-col>
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="
            this.$route.query.type == 'edit'
              ? '自定义课程编辑'
              : this.$route.query.type == 'view'
              ? '自定义课程查看'
              : '自定义课程新增'
          "
          @head-save="headSave"
          @head-save-back="headSave(true)"
          @head-cancel="headCancel"
        ></head-layout>
      </el-col>
    </el-row>

    <div class="newZtFormBox" style="background-color: white; padding: 20px">
      <div class="imgContainer">
        <div v-if="type == 'trainingTaskList'||type=='view'">
          <div
            class="imgContent"
          >
            <img v-if="form.courseCover == ''" src="@/assets/training/trainingDefaultPicture.png"/>
            <img v-else :src="form.courseCover"/>
          </div>
        </div>
        <div v-else>
          <el-upload
            action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
            :headers="headers"
            :show-file-list="false"
            file="file"
            :on-success="handleFileCoverSuccess"
            :before-upload="beforeAvatarUpload"
            accept=".jpeg,.jpg,.gif,.png"
          >
            <div
              class="imgContent"
              :class="showHover ? 'imgHover' : ''"
              @mouseover="changeHover(true)"
              @mouseleave="changeHover(false)"
            >
              <img v-if="form.courseCover == ''" src="@/assets/training/trainingDefaultPicture.png"/>
              <img v-else :src="form.courseCover"/>
              <el-button v-if="showHover" class="btn">重新上传</el-button>
            </div>
          </el-upload>
        </div>
      </div>
      <div>
        <el-form
          ref="ruleForm"
          :model="form"
          :rules="rules"
          label-width="120px"
        >
          <el-row>
            <el-col :span="24" v-if="type == 'trainingTaskList'">
              <el-form-item label="课程名称">
                <el-input
                  v-model="form.courseName"
                  placeholder="请选择课程名称"
                  maxlength="255"
                  @focus="chooseACourse"
                  readonly
                  :disabled="
                    this.trainingTaskListType == 'edit' ||
                    this.trainingTaskListType == 'view'
                  "
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-else>
              <el-form-item label="课程名称" prop="courseName">
                <el-input
                  v-model="form.courseName"
                  :disabled="readonly"
                  placeholder="课程名称"
                  maxlength="255"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="课程分类" prop="trainContentId">
                <avue-input-tree checkStrictly
                                 default-expand-all
                                 v-model="form.trainContentId"
                                 placeholder="请选择内容"
                                 :props="defaultProps"
                                 :disabled="readonly"
                                 :dic="trainingScenariosTree"></avue-input-tree>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="合计课时">
                <el-input-number
                  v-model="totalHours"
                  :precision="2"
                  :step="0.1"
                  :max="10000"
                  disabled
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="达标课时" prop="meetLesson">
                <el-input-number
                  v-model="form.meetLesson"
                  :disabled="readonly"
                  :precision="1"
                  :step="0.5"
                  :max="10000"
                  step-strictly
                  :min="0"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
<!--          <el-row>-->
<!--            <el-col :span="24">-->
<!--              <el-form-item label="课程类型" prop="courseType">-->
<!--                <el-radio-group v-model="form.courseType" :disabled="readonly">-->
<!--                  <el-radio label="1">继续教育</el-radio>-->
<!--                  <el-radio label="2">场景培训</el-radio>-->
<!--                </el-radio-group>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->
          <el-row>
            <el-col :span="24">
              <el-form-item label="课程简介" prop="remark">
                <el-input
                  v-model="form.remark"
                  :disabled="readonly"
                  placeholder="说明"
                  type="textarea"
                  maxlength="300"
                  show-word-limit
                  :rows="3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="视频" name="video">
        <head-layout
          :head-btn-options="subTableheadBtnOptions"
          head-title=""
          @head-remove="headRemove"
          @head-add-tabs="subTableAdd"
          @head-knowledge="headKnowledge"
          @head-save="headSaveVideo"
        ></head-layout>
        <grid-layout
          ref="gridLayoutVide"
          class="serviceType"
          :grid-row-btn="gridRow"
          :table-options="subTableOption"
          :table-data="videdata"
          :table-loading="false"
          @gird-handle-select-click="selectionChange"
          @row-view="rowView"
          @row-remove="rowRemove"
          @row-add="rowAdd"
        >
        </grid-layout>
      </el-tab-pane>
      <el-tab-pane label="文档" name="file">
        <head-layout
          :head-btn-options="subTableheadBtnOptions"
          head-title=""
          @head-remove="headRemove"
          @head-save="headSaveFile"
          @head-add-tabs="subTableAddHeadCite"
          @head-knowledge="coursewareHeadKnowledge"
        ></head-layout>
        <grid-layout
          ref="coursewareCrud"
          class="serviceType"
          :grid-row-btn="coursewareGridRowBtn"
          :table-options="coursewareTableOption"
          :table-data="coursewareData"
          :table-loading="false"
          @gird-handle-select-click="selectionChange"
          @row-view="rowView"
          @row-remove="rowRemove"
          @row-add="rowAdd"
        >
        </grid-layout>
      </el-tab-pane>
      <el-tab-pane label="试题" name="question">
        <head-layout
          head-title=""
          :head-btn-options="testItemContent"
          @head-remove="headRemove"
          @head-cite="testQuestionsHeadCite"
        ></head-layout>
        <grid-layout
          ref="crud"
          class="serviceType"
          :grid-row-btn="gridRowBtn"
          :table-options="testQuestionssubTableOption"
          :table-data="testQuestionsData"
          :table-loading="false"
          @gird-handle-select-click="selectionChange"
          @row-view="rowViewTestQuestions"
          @row-remove="rowRemove"
        >
        </grid-layout>
      </el-tab-pane>
    </el-tabs>
    <CommonDialog v-if="showKnowledgeDialog" class="paperDialog knowledge" width="60%" dialogTitle="选择知识"
                  @cancel="closeKnowledgeDialog" @confirm="confirmKnowledge">
      <div style="display: flex">
        <CommonTree
          style="flex-shrink: 0"
          :treeData="treeData"
          :defaultProps="defaultProps"
          :loading="treeLoading"
          :searchTitle="searchTitle"
          :showCheckbox="false"
          treeTitle="知识库"
          @getNodeClick="repositoryTreeNodeClick"
        />
        <div style="width: calc(100% - 280px)">
          <grid-layout
            ref="gridKnowledgeLayOut"
            :table-options="tableKnowledgeOptions"
            :table-data="tableKnowledgeData"
            :table-loading="tableKnowledgeLoading"
            :data-total="pageKnowledge.total"
            :page="pageKnowledge"
            :selectable="selectable"
            @page-current-change="onLoadKnowledge"
            @page-size-change="onLoadKnowledge"
            @page-refresh-change="onLoadKnowledge"
            @gird-handle-select-click="selectionKnowledgeChange"
          ></grid-layout>
        </div>
      </div>
    </CommonDialog>
    <CommonDialog v-if="showExaminationDialog" class="paperDialog" width="80%" dialogTitle="选择试卷" @cancel="closeDialog"
                  @confirm="confirm">
      <el-container>
        <CommonTree
          :titleShow="false"
          :treeData="examinationTreeData"
          :defaultProps="examinationDefaultProps"
          :searchTitle="searchTitle"
          :showCheckbox="false"
          @getNodeClick="treeNodeClick"
        />
        <el-main>
          <grid-head-layout
            ref="gridHeadLayout"
            :search-columns="searchColumns"
            @grid-head-search="gridHeadSearch"
            @grid-head-empty="gridHeadEmpty"
          ></grid-head-layout>
          <grid-layout
            ref="gridLayOut"
            class="paperCurd"
            :table-options="tableOptions"
            :table-data="tableData"
            :table-loading="tableLoading"
            :data-total="paperPage.total"
            :page="paperPage"
            @page-current-change="onLoad"
            @page-size-change="onLoad"
            @page-refresh-change="onLoad"
            :selectable="selectable"
            @gird-handle-select-click="selectionChange"
          ></grid-layout>
        </el-main>
      </el-container>
    </CommonDialog>
    <CommonDialog v-if="showFileDialog" :showConfirmBtn="false" width="70%"
                  :dialogTitle="fileType == 'file'?'文档预览':'视频预览'" @cancel="showFileDialog = false">
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
    </CommonDialog>
    <CommonDialog v-if="showVideo" width="35%" dialogTitle="上传" @cancel="showVideo = false" @confirm="videoSubmission">
      <el-form
        ref="rulesPopups"
        :model="form"
        :rules="rulesPopups"
        label-width="120px"
        v-if="showVideo"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="dialogType == 'courseware' ? '课件名称' : '课程名称'"
              prop="courseName"
            >
              <el-input
                v-model="formVideo.fileName"
                :placeholder="
                  dialogType == 'courseware' ? '课件名称' : '课程名称'
                "
                maxlength="50"
                style="width: 240px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="dialogType == 'courseware' ? '课件上传' : '课程上传'">
              <SingleFIleUpload
                ref="SingleFIleUpload"
                @fileIds="handleAvatarSuccess"
                accept=""
                fileSize="2000"
                :beforeUpload="beforeUpload"
                @handleRemove="handleRemove"
              ></SingleFIleUpload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </CommonDialog>
    <video
      ref="video"
      id="myVideo"
      :src="videoUrl"
      crossOrigin="anonymous"
      style="display: none"
    ></video>
    <canvas
      id="myCanvas"
      style="display: none"
      crossOrigin="anonymous"
    ></canvas>
    <el-dialog
      top="9vh"
      v-if="showKnowledgeSearchDialog"
      title="知识库选择"
      :visible.sync="showKnowledgeSearchDialog"
      width="80%"
    >
      <KnowledgeSearchDialog
        ref="knowledgeSearchDialog"
        :dialogType="dialogType"
        :selectable="knowledgeSelectable"
        @close-dialog="closeKnowledgeSearchDialog"
        @select-data="selectData"
      ></KnowledgeSearchDialog>
    </el-dialog>
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import CommonTree from "@/views/components/com_tree";
import {platform, putFile} from "@/api/system/user";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {mapGetters} from "vuex";
import {
  categoryHeadTree,
  onTheJobTraininglist,
  retentionTime,
  saveCourse,
  trainContentAdd,
  trainContentRemove,
  trainGroupScenDetail,
  trainGroupSceneSubmit,
} from "@/api/onTheJobTraining/training";
import {getList} from "@/api/training/edupaper";
import {getEduTree} from "@/api/training/examinationPaper";
import website from "@/config/website";
import {getToken} from "@/util/auth";
import TreeLayout from "@/views/components/layout/tree-layout";
import SingleFIleUpload from "@/components/file-upload/SingleFIleUpload";
import {dictionaryBiz} from "@/api/reportTasks";
import KnowledgeSearchDialog from "@/views/components/knowledge/KnowledgeSearchDialog";
import {getSafetyTrainingTree} from "@/api/knowledgeClassifcation/knowledge";
import CommonDialog from "@/components/CommonDialog";

export default {
  props: {
    chooseACourseTask: {
      type: Function,
      default: null,
    },
    trainingTaskList: {
      type: String,
      default: "",
    },
  },
  name: "dataCoonfigAdd",
  mixins: [exForm, draft],
  computed: {
    ...mapGetters(["userInfo"]),
    headers: function () {
      return {"Sinoma-Auth": getToken()};
    },
    totalHours() {
      let number = 0;
      this.coursewareData.forEach((item) =>{
        number += item.classHour
      })
      this.videdata.forEach((item) =>{
        number += item.classHour
      })
      this.form.totalHours = number;
      return number
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.$route.query.type != "view" && this.type != "trainingTaskList") {
        buttonBtn.push({
          label: this.$t("cip.cmn.btn.saveBtn"),
          emit: "head-save",
          type: "button",
          icon: "",
        });
        buttonBtn.push({
          label: this.$t("cip.cmn.btn.saveBackBtn"),
          emit: "head-save-back",
          type: "button",
          icon: "",
        });
      }
      if (this.type != "trainingTaskList") {
        buttonBtn.push({
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-cancel",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
    subTableheadBtnOptions() {
      let buttonBtn = [];
      if (this.$route.query.type != "view" && this.type != "trainingTaskList") {
        buttonBtn.push({
          label: "知识库选择",
          emit: "head-knowledge",
          type: "button",
          icon: "",
          btnOptType: "knowledge",
        });
        // buttonBtn.push({
        //   label: "知识库选择",
        //   emit: "head-cite",
        //   type: "button",
        //   icon: "",
        //   btnOptType: "cite",
        // });
        buttonBtn.push({
          label: "新增",
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
        buttonBtn.push({
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        });
      }

      return buttonBtn;
    },
    testItemContent() {
      let buttonBtn = [];
      if (this.$route.query.type != "view" && this.type != "trainingTaskList") {
        buttonBtn.push({
          label: "试题库选择",
          emit: "head-cite",
          type: "button",
          icon: "",
          btnOptType: "cite",
        });
        buttonBtn.push({
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        });
      }
      return buttonBtn;
    },
    gridRow() {
      let buttonBtn = [];
      if (
        this.videdata.length > 0 &&
        this.videdata != null &&
        this.videdata[0].fileName != ""
      ) {
        if (
          (this.$route.query.type == "edit" ||
            this.$route.query.type == "add") &&
          this.type != "trainingTaskList"
        ) {
          buttonBtn.push({
            label: "删除",
            emit: "row-remove",
            type: "button",
            icon: "",
            btnOptType: "remove",
          });
        }
        if (
          this.$route.query.type == "view" ||
          this.type == "trainingTaskList"
        ) {
          buttonBtn.push({
            label: "查看",
            emit: "row-view",
            type: "button",
            icon: "",
          });
        }
      }
      return buttonBtn;
    },
    coursewareGridRowBtn() {
      let buttonBtn = [];
      if (
        this.coursewareData.length > 0 &&
        this.coursewareData != null &&
        this.coursewareData[0].fileName != ""
      ) {
        if (
          (this.$route.query.type == "edit" ||
            this.$route.query.type == "add") &&
          this.type != "trainingTaskList"
        ) {
          buttonBtn.push({
            label: "删除",
            emit: "row-remove",
            type: "button",
            icon: "",
            btnOptType: "remove",
          });
        }
        if (
          this.$route.query.type == "view" ||
          this.type == "trainingTaskList"
        ) {
          buttonBtn.push({
            label: "查看",
            emit: "row-view",
            type: "button",
            icon: "",
          });
        }
      }
      return buttonBtn;
    },
    gridRowBtn() {
      let buttonBtn = [];
      if (
        this.testQuestionsData.length > 0 &&
        this.testQuestionsData != null &&
        this.testQuestionsData[0].paperName != ""
      ) {
        if (
          (this.$route.query.type == "edit" ||
            this.$route.query.type == "add") &&
          this.type != "trainingTaskList"
        ) {
          buttonBtn.push({
            label: "删除",
            emit: "row-remove",
            type: "button",
            icon: "",
            btnOptType: "remove",
          });
        }
        if (
          this.$route.query.type == "view" ||
          this.type == "trainingTaskList"
        ) {
          buttonBtn.push({
            label: "查看",
            emit: "row-view",
            type: "button",
            icon: "",
          });
        }
      }
      return buttonBtn;
    },
    searchColumns() {
      return [
        {
          prop: "code",
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "试卷编号",
          span: 4,
        },
        {
          prop: "paperName",
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "试卷名称",
          span: 4,
        },
        // {
        //   placeholder: this.$t("cip.cmn.rule.selectWarning") + "考试级别",
        //   prop: "lexaminationLevel",
        //   type: "select",
        //   span: 4,
        //   dicUrl:
        //     "/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_EXAMINATION_LEVEL",
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey",
        //   },
        // },
      ];
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        selectable: this.selectable,
        menu: false,
        column: [
          {
            label: "试卷编号",
            prop: "code",
            width: 180,
            align: "left",
            overHidden: true,
          },
          {
            label: "试卷名称",
            prop: "paperName",
            align: "left",
            overHidden: true,
          },
          // {
          //   label: "考试级别",
          //   prop: "lexaminationLevel",
          //   align: "center",
          //   dicUrl:
          //     "/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_EXAMINATION_LEVEL",
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey",
          //   },
          //   width: 110,
          //   overHidden: true,
          // },
          // {
          //   label: "出题依据",
          //   prop: "accordingTo",
          //   align: "center",
          //   dicUrl:
          //     "/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_ACCORDING_TO",
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey",
          //   },
          //   width: 150,
          //   overHidden: true,
          // },
          {
            label: "专业分类",
            prop: "subjectType",
            dicData: [],
            type: "tree",
            props: {
              label: "title",
              value: "value",
            },
            dataType: "string",
            align: "center",
            width: 200,
            overHidden: true,
          },

          {
            label: "试卷状态",
            prop: "paperStatus",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=paper_status",
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            width: 110,
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
    //知识库
    tableKnowledgeOptions() {
      return {
        index: true,
        indexLabel: "序号",
        menu: false,
        selectable: (row) => {
          if (row.attachList[0]) {
            return (
              this.selectedKnowledgeIds.indexOf(row.attachList[0].id) === -1
            );
          }
        },
        column: [
          {
            label: "名称",
            align: "left",
            prop: "fileName",
            overHidden: true,
          },
          {
            label: "格式",
            align: "center",
            prop: "extension",
            width: 80,
          },
          {
            label: "上传人",
            align: "center",
            prop: "createName",
            width: 80,
            dicData: [],
          },
          {
            label: "时长",
            align: "center",
            prop: "duration",
            width: 80,
            hide: this.dialogType === "courseware",
          },
          {
            label: "上传时间",
            align: "center",
            prop: "updateTime",
            width: 180,
          },
          {
            label: "状态",
            align: "center",
            prop: "status",
            width: 80,
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=is_training_plan_status",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
          },
        ],
      };
    },
    testQuestionssubTableOption() {
      return {
        menuWidth: 120,
        column: [
          {
            label: "试题名称",
            prop: "paperName",
            cell: true,
          },
          {
            label: "专业分类",
            prop: "subjectType",
            dicData: [],
            type: "tree",
            props: {
              label: "title",
              value: "value",
            },
            dataType: "string",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
  },
  data() {
    return {
      fileType: "",
      treeOptions: {
        defaultExpandAll: false,
        nodeKey: "id",
        lazy: true,
        treeLoad: function (node, resolve) {
          const parentId = node.level === 0 ? 0 : node.data.id;
          categoryHeadTree(parentId).then((res) => {
            resolve(
              res.data.data.map((item) => {
                return {
                  ...item,
                  leaf: !item.hasChildren,
                };
              })
            );
          });
        },
        menu: false,
        addBtn: false,
        // size: 'mini',
        props: {
          labelText: this.$t("cip.plat.sys.user.field.title"),
          label: "title",
          value: "value",
          children: "children",
        },
      },
      fileList: [],
      treeData: [],
      trainingScenariosTree: [],//培训类型树结构
      defaultProps: {
        label: "title",
        value: "value",
        key: "key",
      },
      // courseTypeList: [], //课程类型
      repositoryTreeData: [],
      videoUrl: "",
      thumbnail: "",
      videoButton: true,
      formVideo: {
        fileName: ""
      },
      showVideo: false,
      fileUrl: "",
      selectionList: [],
      searchTitle: "title",
      tableData: [],
      examinationTreeData: [],
      tableKnowledgeData: [],
      tableKnowledgeLoading: false,
      selectedKnowledgeList: [],
      readonly: false,
      showKnowledgeDialog: false,
      showKnowledgeSearchDialog: false,
      treeLoading: false,
      form: {
        courseCover: '',
        // courseType: "2",
        trainContentId: "",
        totalHours: "0",
        meetLesson: "0",
        educationalTime: [],
      },
      type: "",
      trainingTaskListType: "",
      data: [],
      processInstanceId: "",
      dialogType: "",
      testQuestionsData: [],
      videdata: [],
      coursewareData: [],
      tableLoading: false,
      showFileDialog: false,
      paperIds: [],
      selectedKnowledgeIds: [],
      pageKnowledge: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      onLoadKnowledgeForm: {
        fileTypeId: "",
        knowledgeType: "",
      },
      repositoryDefaultProps: {
        label: "title",
        value: "value",
        key: "key",
      },
      examinationDefaultProps: {
        label: "title",
        value: "id",
        key: "id",
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      showExaminationDialog: false,
      paperPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      subTableOption: {
        customAddBtn: true,
        addBtn: false,
        editBtn: false,
        rowKey: "id",
        rowParentKey: "parentId",
        menuWidth: 120,
        column: [
          {
            label: "课程名称",
            prop: "fileName",
          },
          {
            label: "时长",
            width: 200,
            prop: "fileTag",
          },
          {
            label: "课时",
            prop: "classHour",
            width: 200,
            type: "number",
            cell: true,
            minRows: 0,
            maxRows: 1000000000,
            precision: 0,
            disabled: !["edit", "add", "notice"].includes(
              this.$route.query.type
            ),
          },
          {
            label: "是否必修",
            prop: "status",
            width: 200,
            type: "switch",
            align: "center",
            props: {
              label: "label",
              value: "value",
            },
            disabled: !["edit", "add"].includes(this.$route.query.type),
            dataType: "string",
            overHidden: true,
            cell: true,
            dicData: [
              {
                label: "否",
                value: "2",
              },
              {
                label: "是",
                value: "1",
              },
            ],
          },
        ],
      },
      coursewareTableOption: {
        customAddBtn: true,
        addBtn: false,
        editBtn: false,
        rowKey: "id",
        rowParentKey: "parentId",
        menuWidth: 120,
        column: [
          {
            label: "课件名称",
            prop: "fileName",
          },
          {
            label: "类型",
            prop: "extension",
          },
          {
            label: "课时",
            prop: "classHour",
            width: 200,
            type: "number",
            cell: true,
            minRows: 0,
            maxRows: 1000000000,
            precision: 0,
            disabled: !["edit", "add", "notice"].includes(
              this.$route.query.type
            ),
          },
        ],
      },
      rulesPopups: {
        courseName: [
          {
            required: true,
            message: "请输入课程名称",
            trigger: "blur",
          },
        ],
      },
      rules: {
        courseName: [
          {
            required: true,
            message: "请输入课程名称",
            trigger: "blur",
          },
        ],
        trainContentId: [
          {
            required: true,
            message: "请输入培训类型",
            trigger: "blur",
          },
        ],
        // courseType: [
        //   {
        //     required: true,
        //     message: "请选择课程类型",
        //     trigger: "blur",
        //   },
        // ],
      },
      showHover: false,
      activeName: "video",
    };
  },
  components: {
    FormLayout,
    GridLayout,
    HeadLayout,
    CommonTree,
    TreeLayout,
    SingleFIleUpload,
    KnowledgeSearchDialog,
    CommonDialog
  },
  created() {
  },
  mounted() {
    getSafetyTrainingTree()
      .then((result) => {
        this.trainingScenariosTree = result.data.data;
        //判断是否是从培训任务中调用过来的
        if (this.trainingTaskList == "trainingTaskList") {
          this.type = "trainingTaskList";
          this.trainingTaskListType = sessionStorage.getItem("taskType");
        } else {
          if (this.$route.query.type == "add") {
            console.log("this.$route.query", this.$route.query)
            this.form.trainContentId = this.$route.query.trainContentId;
            this.form.trainingScenarios = this.$route.query.trainContentName;
          }
          if (this.$route.query.type == "view") {
            this.readonly = true;
          }
          if (
            this.$route.query.type == "edit" ||
            this.$route.query.type == "view"
          ) {
            let row = JSON.parse(this.$route.query.row);
            this.type = this.$route.query.type;
            this.initDate(row.id);
          }
        }
      })
    if (this.type == "trainingTaskList") {
      this.readonly = true;
    }
    // dictionaryBiz("course_type").then((res) => {
    //   this.courseTypeList = res.data.data;
    // });
  },

  methods: {
    changeHover(type) {
      this.showHover = type;
    },
    handleFileCoverSuccess(res, file) {
      this.form.courseCover = res.data.link;
    },
    beforeAvatarUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/gif" ||
        file.type === "image/png";

      if (!isJPG) {
        this.$message.warning("上传图片只能是 自定义文件 格式!");
      }
      if (!isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
      }
      return isJPG && isLt20M;
    },
    selectable(row) {
      return this.paperIds.indexOf(row.id) === -1;
    },
    repositoryTreeNodeClick(node) {
      this.treeDeptId = node.id;
      this.pageKnowledge.currentPage = 1;
      this.onLoadKnowledgeForm.knowledgeType = node.id;
      this.onLoadKnowledgeForm.fileTypeId = node.id;
      this.onLoadKnowledge(this.pageKnowledge);
    },
    videoSubmission() {
      if (
        this.formVideo.fileName == "" ||
        typeof this.formVideo.fileName == "undefined"
      ) {
        return this.$message({
          type: "warning",
          message: this.dialogType == 'courseware' ? '请输入课件名称' : '请输入课程名称',
        });
      }
      saveCourse(this.formVideo).then((res) => {
        this.initDate(this.form.id);
        this.$message({
          type: "success",
          message: "保存成功！",
        });
        this.showVideo = false;
      });
    },
    handleAvatarSuccess(file) {
      this.formVideo.trainContentId = file.response.attachId;
      this.formVideo.trainId = this.form.id;
      this.$message({
        type: "success",
        message: "上传成功！",
      });
      this.videoButton = false;
    },
    treeNodeClick(node) {
      let params = {
        subjectType: node.id,
      };
      this.paperPage.currentPage = 1;
      this.onLoad(this.paperPage, params);
    },
    dataFromat(list) {
      let a = [];
      list.forEach((e) => {
        a.push(this.fun2(e));
      });
      Promise.all(a).then((res) => {
        retentionTime({kmTrainContentList: res});
      });
    },
    formatTime(seconds) {
      var hours = Math.floor(seconds / 3600);
      var minutes = Math.floor((seconds % 3600) / 60);
      var remainingSeconds = seconds % 60;

      // 将时、分、秒转换为两位数的格式
      var hoursStr = this.padZero(hours);
      var minutesStr = this.padZero(minutes);
      var secondsStr = this.padZero(remainingSeconds);

      return hoursStr + ":" + minutesStr + ":" + secondsStr;
    },
    padZero(num) {
      return (num < 10 ? "0" : "") + num;
    },
    fun2(e) {
      return new Promise((resolve) => {
        let that = this;
        var video = document.createElement("video");
        video.src = e.fileCover;
        video.addEventListener("loadedmetadata", function () {
          var seconds = video.duration;
          e.fileTag = that.formatTime(Math.floor(seconds));
          let cc = {
            trainId: that.form.id,
            trainContentId: e.id,
            duration: e.fileTag,
          };
          resolve(cc);
        });
      });
    },
    /**
     * 用户清空数据
     */
    empty() {
      this.form = "";
      this.videdata = [];
      this.coursewareData = [];
      this.testQuestionsData = [];
    },
    async initDate(id) {
      trainGroupScenDetail(id).then((res) => {
        this.initTree();
        // this.initKnowledgeClassificationTree();
        this.form = res.data.data;
        if (res.data.data.kmInfoVideoList.length > 0) {
          this.dataFromat(res.data.data.kmInfoVideoList);
        }
        this.videdata = res.data.data.kmInfoVideoList;
        this.videdata.forEach((item, index) => {
          this.$refs.gridLayoutVide.getGrid().rowCell(item, index);
        });
        res.data.data.kmInfoCoursewareList.forEach((item, index) => {
          this.$refs.coursewareCrud.getGrid().rowCell(item, index);
        });
        this.coursewareData = res.data.data.kmInfoCoursewareList;
        this.testQuestionsData = res.data.data.eduPaperList;

        // this.secondMethod(res)
      });
    },
    base64ToFile(base64Data, filename) {
      // 将base64的数据部分提取出来
      const parts = base64Data.split(";base64,");
      const contentType = parts[0].split(":")[1];
      const raw = window.atob(parts[1]);

      // 将原始数据转换为Uint8Array
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      // 使用Blob和提取出的数据内容创建一个新的Blob对象
      const blob = new Blob([uInt8Array], {type: contentType});

      // 创建一个指向Blob对象的URL，并使用这个URL创建一个File对象
      const blobUrl = URL.createObjectURL(blob);
      const file = new File([blob], filename, {type: contentType});

      // 返回File对象
      return file;
    },
    beforeUpload(file) {
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf("."));
      this.$set(this.formVideo, 'fileName', fileName.split(fileType)[0])
      if (this.dialogType == "courseware") {
        this.formVideo.type = 2;
        if (
          fileType !== ".jpg" &&
          fileType !== ".jpeg" &&
          fileType !== ".png" &&
          fileType !== ".pdf" &&
          fileType !== ".word" &&
          fileType !== ".doc" &&
          fileType !== ".docx" &&
          fileType !== ".xls" &&
          fileType !== ".xlsx" &&
          fileType !== ".txt" &&
          fileType !== ".pptx" &&
          fileType !== ".ppt"
        ) {
          this.$message({
            type: "warning",
            message:
              "请上传jpg、jpeg、png、pdf、word、doc、docx、xls、xlsx、txt、ppt、pptx类型文件！",
          });
          return false;
        }
      } else if (this.dialogType == "video") {
        this.formVideo.type = 1;
        if (
          fileType !== ".mp4" &&
          fileType !== ".avi" &&
          fileType !== ".mkv" &&
          fileType !== ".mov" &&
          fileType !== ".wmv" &&
          fileType !== ".flv" &&
          fileType !== ".mpeg"
        ) {
          this.$message({
            type: "warning",
            message: "请上传mp4、avi、mkv、mov、wmv、flv、mpeg类型文件！",
          });
          return false;
        }
      }
      // const isLt20M = file.size / 1024 / 1024 < 20;
      // if (!isLt20M) {
      //   this.$message.warning('上传文件大小不能超过 20MB!');
      // }
      // return isLt20M;
      return true;
    },
    handleRemove(file, fileList) {
      this.videoButton = true;
    },
    coursewareForm() {
      this.form.eduPaperList = [];
      this.form.kmInfoVideoList = [];
      let date = [];
      this.selectionKnowledgeList.forEach((item) => {
        let aa = {
          fileName: item.fileName,
          id: item.attachList[0].id,
        };
        date.push(aa);
      });
      this.form.kmInfoCoursewareList = date;
      this.trainGroupScene();
    },
    trainGroupScene() {
      trainGroupSceneSubmit(this.form).then((res) => {
        this.initDate(res.data.data.id);
        this.showKnowledgeDialog = false;
        return this.$message({
          type: "success",
          message: "操作成功！",
        });
      });
    },
    gridHeadEmpty(query) {
      this.onLoad(this.paperPage, query);
    },
    confirm() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择试卷");
        return;
      }
      this.form.kmInfoVideoList = [];
      this.form.kmInfoCoursewareList = [];
      let cc = []
      this.selectionList.forEach((item) => {
        cc.push({
          paperName: item.paperName,
          trainContentId: item.id
        })
      })
      this.form.eduPaperList = cc;
      this.trainGroupScene();
      this.closeDialog();
    },
    videoForm() {
      this.form.eduPaperList = [];
      this.form.kmInfoCoursewareList = [];
      let date = [];
      this.selectionKnowledgeList.forEach((item) => {
        let aa = {
          fileName: item.fileName,
          id: item.attachList[0].id,
        };
        date.push(aa);
      });
      this.form.kmInfoVideoList = date;
      this.trainGroupScene();
    },
    confirmKnowledge() {
      if (this.dialogType == "courseware") {
        this.coursewareForm();
      } else if (this.dialogType == "video") {
        this.videoForm();
      }
    },
    knowledgeSelectable(row) {
      const disabled = [];
      if (this.dialogType === "courseware") {
        this.form.kmInfoCoursewareList.forEach((item) => {
          disabled.push(item.trainContentId);
        });
      } else if (this.dialogType === "video") {
        this.form.kmInfoVideoList.forEach((item) => {
          disabled.push(item.trainContentId);
        });
      }
      return !disabled.includes(row.attachIds);
    },
    selectData(data) {
      this.form.eduPaperList = [];
      this.form.kmInfoCoursewareList = [];
      this.form.kmInfoVideoList = [];
      let result = [];
      data.forEach((item) => {
        let attachIdList = item.attachIds.split(",");
        if (attachIdList.length > 0) {
          let aa = {
            fileName: item.fileName,
            trainContentId: attachIdList[0],
          };
          result.push(aa);
        } else {
          return this.$message({
            type: "warning",
            message: "名为" + item.fileName + "的附件不存在",
          });
        }
      });

      if (this.dialogType === "courseware") {
        this.form.kmInfoCoursewareList = result;
      } else if (this.dialogType === "video") {
        this.form.kmInfoVideoList = result;
      }
      trainGroupSceneSubmit(this.form).then((res) => {
        this.initDate(res.data.data.id);
        this.closeKnowledgeDialog();
        return this.$message({
          type: "success",
          message: "操作成功！",
        });
      });
    },
    closeKnowledgeSearchDialog() {
      this.dialogType = "";
      this.showKnowledgeSearchDialog = false;
    },
    headSaveVideo() {
      let cc = {
        id: this.form.id,
        totalHours: this.form.totalHours,
        kmInfoVideoList: this.videdata,
      };
      trainContentAdd(cc).then((res) => {
        this.initDate(res.data.data.id);
        return this.$message({
          type: "success",
          message: "操作成功！",
        });
      });
    },
    headKnowledge() {
      if (!this.form.id) {
        return this.$message.warning("请先保存头表信息");
      }
      this.dialogType = "video";
      this.showKnowledgeSearchDialog = true;
    },
    coursewareHeadKnowledge() {
      if (!this.form.id) {
        return this.$message.warning("请先保存头表信息");
      }
      this.dialogType = "courseware";
      this.showKnowledgeSearchDialog = true;
    },
    selectionKnowledgeChange(list) {
      this.selectionKnowledgeList = list;
    },
    //知识库
    closeKnowledgeDialog() {
      this.pageKnowledge.currentPage = 1;
      this.showKnowledgeDialog = false;
    },
    closeDialog() {
      this.selectionList = [];
      this.paperPage.currentPage = 1;
      this.showExaminationDialog = false;
    },

    initTree() {
      getEduTree().then((res) => {
        let {data} = res.data;
        this.examinationTreeData = data;
        let testQuestionColumn = this.findObject(
          this.testQuestionssubTableOption.column,
          "subjectType"
        );
        testQuestionColumn.dicData = data;
      });
    },
    testQuestionsHeadCite() {
      if (!this.form.id) {
        return this.$message.error("请先保存头表信息");
      }
      this.paperIds = [];
      this.showExaminationDialog = true;
      this.initTree();
      let column = this.findObject(this.tableOptions.column, "subjectType");
      column.dicData = this.examinationTreeData;

      this.form.eduPaperList.forEach((item) => {
        this.paperIds.push(item.id);
      });
      this.onLoad(this.paperPage);
    },
    rowView(item) {
      this.fileType = item.fileType
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(item.fileCover));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    onLoad(page, params = {}) {
      this.paperPage = page;
      this.tableLoading = true;
      //只获取试卷类型为已发布的
      params.paperStatus = 1;
      getList(page.currentPage, page.pageSize, Object.assign(params)).then(
        (res) => {
          const data = res.data.data;
          this.tableData = data.records;
          this.tableLoading = false;
          this.$nextTick(() => {
            this.$refs.gridLayOut.page.total = data.total;
            this.$refs.gridLayOut.selectionClear();
          });
        }
      );
    },
    coursewareHeadCite() {
      if (!this.form.id) {
        return this.$message.error("请先保存头表信息");
      }
      this.onLoadKnowledgeForm = {};
      this.showKnowledgeDialog = true;
      this.selectionKnowledgeList = [];
      this.selectedKnowledgeIds = [];
      this.onLoadKnowledgeForm = [];
      this.form.kmInfoCoursewareList.forEach((item) => {
        this.selectedKnowledgeIds.push(item.trainContentId);
      });
      this.dialogType = "courseware";
      categoryHeadTree("000000")
        .then((result) => {
          this.treeData = result.data.data;
          this.node = this.treeData[0];
          this.treeLoading = false;
          this.onLoadKnowledge(this.pageKnowledge);
        })
        .catch((err) => {
          this.treeLoading = true;
        });
    },
    gridHeadSearch(query) {
      this.paperPage.currentPage = 1;
      this.onLoad(this.paperPage, query);
    },
    headCite() {
      if (!this.form.id) {
        return this.$message.error("请先保存头表信息");
      }
      this.onLoadKnowledgeForm = {};
      this.showKnowledgeDialog = true;
      this.selectionKnowledgeList = [];
      this.onLoadKnowledgeForm = [];
      this.selectedKnowledgeIds = [];
      this.form.kmInfoVideoList.forEach((item) => {
        this.selectedKnowledgeIds.push(item.trainContentId);
      });
      this.dialogType = "video";
      categoryHeadTree("000000")
        .then((result) => {
          this.treeData = result.data.data;
          this.node = this.treeData[0];
          this.treeLoading = false;
          this.onLoadKnowledge(this.pageKnowledge);
        })
        .catch((err) => {
          this.treeLoading = true;
        });
    },
    onLoadKnowledge(page, knowledgeType, fileTypeId) {
      this.pageKnowledge = page;
      this.tableKnowledgeLoading = true;
      onTheJobTraininglist(page.currentPage, page.pageSize, {
        documentType: this.dialogType,
        knowledgeType: this.onLoadKnowledgeForm.knowledgeType,
        fileTypeId: this.onLoadKnowledgeForm.fileTypeId,
      }).then((res) => {
        const data = res.data.data;
        if (this.isEmptyObject(data)) {
          this.$refs.gridKnowledgeLayOut.page.total = 0;
          this.pageKnowledge.total = 0;
        } else {
          this.$refs.gridKnowledgeLayOut.page.total = data.total;
          this.pageKnowledge.total = data.total;
        }
        this.tableKnowledgeData = data.list;
        this.tableKnowledgeLoading = false;
      });
    },
    isEmptyObject(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    subTableAdd() {
      if (!this.form.id) {
        return this.$message.error("请先保存头表信息");
      }
      this.fileList = [];
      this.showVideo = true;
      this.videoButton = true;
      this.formVideo = {};
      this.dialogType = "video";
      // this.$router.push({
      //   path: `/knowledge/knowledgeUpload`,
      //   query: {
      //     type: "add",
      //   },
      // });
    },
    subTableAddHeadCite() {
      if (!this.form.id) {
        return this.$message.error("请先保存头表信息");
      }
      this.fileList = [];
      this.dialogType = "courseware";
      this.showVideo = true;
      this.videoButton = true;
      this.formVideo = {};
    },
    rowViewTestQuestions(row) {
      this.$router.push({
        path: "/training/paper/edupaperView",
        query: {
          row: JSON.stringify(row),
          type: "view",
        },
      });
    },
    headSaveFile() {
      let cc = {
        id: this.form.id,
        totalHours: this.form.totalHours,
        kmInfoCoursewareList: this.coursewareData,
      };
      trainContentAdd(cc).then((res) => {
        this.initDate(res.data.data.id);
        return this.$message({
          type: "success",
          message: "操作成功！",
        });
      });
    },
    rowAdd(row) {
      let listVido = [];
      listVido.push(row);
      let cc = {
        id: this.form.id,
        totalHours: this.form.totalHours,
        kmInfoVideoList: listVido,
      };
      trainContentAdd(cc).then((res) => {
        this.initDate(res.data.data.id);
        return this.$message({
          type: "success",
          message: "操作成功！",
        });
      });
    },
    rowRemove(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        trainContentRemove(this.form.id, row.id).then((res) => {
          this.initDate(this.form.id);
          return this.$message({
            type: "success",
            message: "删除成功！",
          });
        });
      });
    },
    headRemove() {
      if (this.selectionList.length == 0) {
        this.$message.warning("请选择至少一条数据");
        return false;
      }
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        var ids = this.selectionList.map((item) => item.id);
        trainContentRemove(this.form.id, ids.join(",")).then((res) => {
          this.initDate(this.form.id);
          this.selectionList = [];
          return this.$message({
            type: "success",
            message: "删除成功！",
          });
        });
      });
    },

    selectionChange(list) {
      this.selectionList = list;
    },
    chooseACourse() {
      this.chooseACourseTask();
    },
    headSave(cancel = false) {
      if (
        (this.$route.query.type == "add" || this.$route.query.type == "edit") &&
        cancel
      ) {
        var cc = true;
        if (
          this.videdata.length > 0 ||
          this.coursewareData.length > 0 ||
          this.testQuestionsData.length > 0
        ) {
          cc = false;
        }
        if (cc) {
          return this.$message({
            type: "error",
            message:
              "请点击保存按钮在视频内容、课件类型、试题内容中至少保存一条数据再进行返回！",
          });
        }
      }
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          //集团培训
          //如果没有视频使用默认图片
          let that = this;
          var educationalTime = that.form.educationalTime;
          that.form.startDate = that.form.educationalTime[0];
          that.form.endDate = that.form.educationalTime[1];
          that.$loading();
          that.form.type = 2;
          if (this.$route.query.type == "add") {
            that.form.status = 2;
          }
          that.form.methodOfPreservation = "head";
          if (that.form.totalHours > 0 && that.form.meetLesson <= 0) {
            that.$loading().close();
            return that.$message({
              type: "warning",
              message:
                "请输入达标课时,达标课时不能为0",
            });
          }
          try {
            trainGroupSceneSubmit(that.form).then((res) => {
              that.form.methodOfPreservation = "table";
              that.initDate(res.data.data.id);
              that.$message({
                type: "success",
                message: "保存成功！",
              });
              that.$loading().close();
              if (cancel) {
                that.headCancel();
              }
            });
          } catch (e) {
            console.log(e);
          } finally {
            that.$loading().close();
          }
        } else {
          return false;
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.newZtFormBox {
  display: flex;

  .imgContainer {
    width: 40%;
    border: 1px solid #ccc;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .imgContent {
      width: 100%;
      height: 216px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      .btn {
        width: 100px;
        height: 30px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
      }
    }

    .imgHover {
      background: #000000;
      opacity: 0.4;
    }
  }
}

::v-deep .el-tabs__header {
  padding: 0 12px;
  margin: 0;
  background: #fff;
}

::v-deep .el-input-number {
  width: 180px;
}

::v-deep .paperCurd .avue-crud .el-table {
  height: calc(100vh - 406px) !important;
  max-height: calc(100vh - 406px) !important;
}

.knowledgeTree .common_tree_handle .el-tree {
  height: calc(100vh - 500px) !important;
}

::v-deep .avue-tree {
  height: calc(100vh - 417px);
}

//::v-deep .serviceType .avue-crud .el-table .el-table__body-wrapper {
//  height: unset !important;
//}

::v-deep .serviceType .avue-crud .el-table {
  height: auto !important;
}

::v-deep .serviceType .avue-crud .el-table .avue-crud__empty {
  padding: 0;
}

::v-deep .serviceType .avue-crud .el-table .el-empty {
  padding: 0;
}

::v-deep .serviceType .avue-crud .el-table .el-empty__description {
  margin-top: 0;
}

::v-deep .common_tree_handle .el-tree {
  /* height: 100% !important; */
  height: calc(100vh - 459px) !important;
  overflow-y: scroll;
}
</style>
